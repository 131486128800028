import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import SEO from "../components/seo";

function PricingPage() {
  return (
    <div>
      <SEO
        keywords={[`video`, `interview`, `remote`, `hiring`]}
        title="Pricing"
      />

      <Navbar />

      <div className="bg-gray-900">
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto lg:max-w-none">
              <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                Pricing
              </h2>
              <p className="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                Exploding headcount doesn&#8217;t mean exploding prices
              </p>
              <p className="mt-2 text-xl leading-7 text-gray-300">
                Choose a plan that fits your hiring cadence. Pay only what you
                need to retain the best candidates.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div className="relative">
            <div className="absolute inset-0 h-3/4 bg-gray-900"></div>
            <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
                <div className="rounded-lg shadow-lg overflow-hidden">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                        Traction
                      </span>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                      $99
                      <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                        /mo
                      </span>
                    </div>
                    <p className="mt-5 text-lg leading-7 text-gray-500">
                      Perfect for new companies looking for their first hires.
                    </p>
                  </div>
                  <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <div className="flex flex-col ml-3 text-base leading-6 text-gray-700">
                          <p>
                            Includes{" "}
                            <strong className="font-semibold text-gray-900">
                              10
                            </strong>{" "}
                            video or phone interviews{" "}
                          </p>
                          <p>
                            Add more for{" "}
                            <strong className="font-semibold text-gray-900">
                              $25
                            </strong>
                            /each
                          </p>
                        </div>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Unlimited candidates
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Unlimited interviewers
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Up to{" "}
                          <strong className="font-semibold text-gray-900">
                            2
                          </strong>{" "}
                          coordinators
                        </p>
                      </li>
                    </ul>
                    <div className="mt-6 rounded-md shadow">
                      <a
                        href="#"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
                  <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                        Scaling
                      </span>
                    </div>
                    <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
                      $399
                      <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                        /mo
                      </span>
                    </div>
                    <p className="mt-5 text-lg leading-7 text-gray-500">
                      Designed for growing companies that are leveling up their
                      workforce in a big way.
                    </p>
                  </div>
                  <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                    <ul>
                      <li className="flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <div className="flex flex-col ml-3 text-base leading-6 text-gray-700">
                          <p>
                            Includes{" "}
                            <strong className="font-semibold text-gray-900">
                              25
                            </strong>{" "}
                            video or phone interviews{" "}
                          </p>
                          <p>
                            Add more for{" "}
                            <strong className="font-semibold text-gray-900">
                              $10
                            </strong>
                            /each
                          </p>
                        </div>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Unlimited candidates
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Unlimited interviewers
                        </p>
                      </li>
                      <li className="mt-4 flex items-start">
                        <div className="flex-shrink-0">
                          <svg
                            className="h-6 w-6 text-green-500"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <p className="ml-3 text-b ase leading-6 text-gray-700">
                          Up to{" "}
                          <strong className="font-semibold text-gray-900">
                            10
                          </strong>{" "}
                          coordinators
                        </p>
                      </li>
                    </ul>
                    <div className="mt-6 rounded-md shadow">
                      <a
                        href="#"
                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      >
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            <div className="max-w-md mx-auto lg:max-w-5xl">
              <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                <div className="flex-1">
                  <div>
                    <span className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-white text-gray-800">
                      Custom
                    </span>
                  </div>
                  <div className="mt-4 text-lg leading-7 text-gray-500">
                    Can&apos;t find the right fit above? Work with us to build a
                    plan that works for your team.
                  </div>
                </div>
                <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                  <a
                    href="#"
                    className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Build Custom Plan
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
            Frequently asked questions
          </h2>
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl>
              <div className="md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  What is a coordinator?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    A <strong className="font-semibold">coordinator</strong> is
                    able to add candidates, schedule interviews, and modify
                    teams. These users are usually members of your recruiting
                    team.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  How do you make holy water?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    You boil the hell out of it. Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Magnam aut tempora vitae odio
                    inventore fuga aliquam nostrum quod porro. Delectus quia
                    facere id sequi expedita natus.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  What do you call someone with no body and no nose?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Nobody knows. Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Culpa, voluptas ipsa quia excepturi,
                    quibusdam natus exercitationem sapiente tempore labore
                    voluptatem.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Why do you never see elephants hiding in trees?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Because theyre so good at it. Lorem ipsum dolor sit amet
                    consectetur adipisicing elit. Quas cupiditate laboriosam
                    fugiat.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Why cant you hear a pterodactyl go to the bathroom?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    Because the pee is silent. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Ipsam, quas voluptatibus ex
                    culpa ipsum, aspernatur blanditiis fugiat ullam magnam
                    suscipit deserunt illum natus facilis atque vero
                    consequatur! Quisquam, debitis error.
                  </p>
                </dd>
              </div>
              <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                  Why did the invisible man turn down the job offer?
                </dt>
                <dd className="mt-2 md:mt-0 md:col-span-7">
                  <p className="text-base leading-6 text-gray-500">
                    He couldnt see himself doing it. Lorem ipsum dolor sit, amet
                    consectetur adipisicing elit. Eveniet perspiciatis officiis
                    corrupti tenetur. Temporibus ut voluptatibus, perferendis
                    sed unde rerum deserunt eius.
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PricingPage;
